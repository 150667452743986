export default {
  sv: {
    path: 'content.sections',
    replace: true,
    data: {
      contentId: 'cTV7WLSDvElOGblJaekLY',
      componentRef: 'CompanyForm',
      title: 'Visste du att Zmarta erbjuder flera olika affärslösningar?',
      text: 'Ta reda på hur du kan spara på försäkringar, lån och el för ditt företag.',
      buttonText: 'Företag'
    }
  }
}
