export default {
  sv: {
    path: 'content.sections',
    position: 0,
    merge: true,
    data: {
      componentRef: 'TrustQuoteSection',
      contentId: 'U5ZPWwni7agLO22J7sqLF',
      title: 'Vi finns här för dig',
      trustTitle: 'Utmärkt',
      imageSrc: '',
      name: '',
      profession: '',
      quote: 'Ni har smarta handläggare och är ett företag som sätter kunderna först. Ni är pålitliga på alla avseenden och därför kommer jag att rekommendera er till alla mina bekanta!',
      backgroundSettings: {
        borderTop: true,
        backgroundColor: 'beige'
      }
    }
  }
}
