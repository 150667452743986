export default {
  sv: {
    path: 'content.sections',
    replace: true,
    data: {
      contentId: '3rMpLl3KJrmaIDOZwqnjRl',
      componentRef: 'LeadForm',
      title: 'Håll mig uppdaterad',
      text: 'Fyll i din mejladress för att hålla dig uppdaterad med de senaste tipsen för en smartare vardag. Få råd om hur du sparar pengar, hittar det bästa elavtalet för dig och djupdyk ner i vilka försäkringar som just du kan få ut mer av – för den lägsta prislappen.',
      input: {
        label: 'Mejl',
        error: 'Ange en korrekt e-mailadress (ex. namn@mail.com)',
        emailWarningLanguage: 'se'
      },
      buttonText: 'Vidare',
      titleCompleted: 'Tack för att du har signat upp dig till Zmartas nyhetsbrev!',
      textCompleted: 'Vi kommer att skicka ett bekräftelsemejl till dig inom 24 timmar.',
      disclaimer: 'Genom att klicka “Vidare” bekräftar du att du tagit del av <a href="https://www.zmarta.se/avtal/dataskyddspolicy">Zmartas dataskyddspolicy</a>.',
      backgroundElement: true
    }
  }
}
